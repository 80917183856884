<template>
  <div class="p-col-12">
    <div class="card">
      <form
        name="taskForm"
        class="p-col-12"
        @submit.prevent="submitServiceForm"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6 p-md-4">
            <b>PAT: </b> {{ service.pat_number }} <br />
            <b>Hora de Início: </b> {{ startTime }}
          </div>
          <div
            class="p-field p-col-6 p-md-4"
            v-if="service.note != 'pat_title'"
          >
            <b>Título: </b> {{ service.pat_title }}
          </div>
          <div class="p-field p-col-12 p-md-4" v-if="service.note != ''">
            <b>Nota: </b> {{ service.note }}
          </div>

          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="resume"
                name="resume"
                :autoResize="true"
                v-model="service.resume"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('resume') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="inputtext">Trabalho efectuado</label>
            </span>
            <small v-if="errors.has('resume')" class="p-error" role="alert">
              Descreva o trabalho efectuado
            </small>
          </div>

          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="description"
                v-model="service.description"
                :autoResize="true"
                v-bind:class="[
                  { 'p-error': errors.has('description') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                name="description"
                rows="5"
              />
              <label for="inputtext">Pendente para a próxima intervenção</label>
            </span>
          </div>

          <div
            class="p-field p-col-12 p-md-12 p-text-right"
            style="color: #91969a"
          >
            <small>
              Criado por {{ service.created_by }} {{ service.created_at }}
              <br />
              Última alteração por {{ service.updated_by }}
              {{ service.updated_at }}
            </small>
          </div>

          <div class="p-grid p-jc-center p-col-12">
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-check"
                label="Guardar"
                class="p-button-success"
                @click="submitServiceForm()"
              />
            </div>
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-times"
                label="Cancelar"
                @click="cancelServiceForm()"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { getOnlyTime } from "../helpers/helpers";
export default {
  name: "HomeTasksListForm",
  props: ["service"],
  data() {
    return {
      loading: false,
      startTime: null,
    };
  },
  mounted() {
    this.startTime = getOnlyTime(new Date(this.service.start));
  },
  methods: {
    submitServiceForm() {
      this.loading = true;

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$emit("submitServiceForm", this.service);
      });
    },
    cancelServiceForm() {
      this.$emit("cancelServiceForm");
    },
  },
};
</script>
