<template>
  <div class="p-grid p-col-12 p-lg-12">
    <div class="p-col-12 p-md-5">
      <HomeTasksList />
    </div>
    <!-- <div class="p-col-12 p-lg-3">
        <HomeVacations/>
        <HomeOpenDays/>
    </div> -->
    <div class="p-col-12 p-md-4">
      <HomeToClose />
    </div>
    <div class="p-col-12 p-md-3">
      <HomeRejectExpenditures/>
      <HomeFleet />
    </div>
  </div>
</template>

<script>
// import homeVacations from "./HomeVacations";
// import homeOpenDays from "./HomeOpenDays";
import homeTasksList from "./HomeTasksList";
import homeToClose from "./HomeToClose";
import HomeFleet from "./HomeFleet.vue";
import HomeRejectExpenditures from "./HomeRejectExpenditures.vue";
import firebaseInitialize from '../firebase'

export default {
  name: "Home",
  components: {
    // HomeVacations: homeVacations,
    // HomeOpenDays: homeOpenDays,
    HomeTasksList: homeTasksList,
    HomeToClose: homeToClose,
    HomeRejectExpenditures,
    HomeFleet,
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  created() {
    firebaseInitialize(this.currentUser.id);
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
