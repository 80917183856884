<template>
  <div
    class="card p-shadow-6"
    :style="{ position: 'relative' }"
    v-if="rejected > 0"
  >
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <Button
      label="Despesas"
      class="p-button-text p-button-lg cursor-default"
    />
    <div class="p-grid p-fluid dashboard p-jc-center">
      <div
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('expenditure/Rejeitadas')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Despesas Rejeitadas</span>
          <span v-bind:class="['count', 'red']">
            {{ rejected }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import expendituresService from "../services/expenditure.service";
export default {
  name: "HomeRejectExpenditures",
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      rejected: 0,
    };
  },
  async created() {
    this.rejected = await expendituresService.countRejected();
    this.loading = false;
  },
  methods: {},
};
</script>
<style>
.count {
  padding: 0px 14px !important;
}
.green {
  background-color: #20d077;
}
.red {
  background-color: #ef6262;
}
.yellow {
  background-color: #f9c851;
}

.dashboard .summary .title {
  font-size: 15px;
}
.p-button-lg {
  color: #000 !important;
  font-size: 1.75rem !important;
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
</style>
