import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class NotificationsService {
  validadeTokenExist(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}notifications/tokenExist`, bodyParms,  {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getNotificationConfig() {
    return axios
      .get(`${config["apiUrl"]}notifications/config`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new NotificationsService();
