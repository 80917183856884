<template>
  <div
    class="card p-shadow-6"
    :style="{ position: 'relative' }"
    v-if="vehicles && vehicles.length > 0"
  >
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <ChangeConductorForm
      :show="changeConductor.show"
      :vehicle="changeConductor.vehicle"
      v-on:cancel="changeConductorCanceled"
      v-on:newConductor="conductorChanged"
    />
    <ReportForm
      :show="reportModel.show"
      :vehicleId="reportModel.vehicleId"
      :reportId="reportModel.reportId"
      :title="reportModel.title"
      v-on:cancel="closeReportForm"
      v-on:savedReport="closeReportForm"
    />
    <Button label="Viaturas" class="p-button-text p-button-lg cursor-default" />
    <div v-for="(vehicle, index) in vehicles" :key="vehicle.id">
      <Card v-if="vehicle.vehicle" class="p-mt-2">
        <template #title>
          <span
              class="clickable-item"
              @click="$router.push(`vehicle-details/${vehicle.vehicle.id}`)"
              >{{ vehicle.vehicle.registration }}
            </span>
        </template>
        <template #subtitle>
          {{ vehicle.vehicle.brand }} | {{ vehicle.vehicle.model }} |
          {{ vehicle.vehicle.color }}</template
        >
        <template #content>
          <p><b>Início da Utilização:</b> {{ vehicle.start }}</p>
          <DataTable
            v-if="vehicle.docs"
            :value="vehicle.docs"
            filterDisplay="row"
            :loading="loading"
            class="p-datatable-sm"
            :sortOrder="1"
            responsiveLayout="scroll"
            removableSort
            sortMode="single"
            :autoLayout="true"
          >
            <template #empty> Não existem Documentos. </template>
            <Column
              field="document_type"
              filterField="document_type"
              header="Tipo"
              filterMatchMode="contains"
            />
            <Column
              field="file_origin_name"
              filterField="document_type"
              header="Documento"
              filterMatchMode="contains"
            >
              <template #body="slotProps">
                <span
                  class="clickable-item"
                  @click="downloadFile(slotProps.data)"
                  >{{ slotProps.data.file_origin_name }}</span
                >
              </template>
            </Column>
          </DataTable>
        </template>
        <template #footer>
          <Button
            icon="pi pi-arrow-right-arrow-left"
            label="Trocar Condutor"
            @click="setNewCondutor(vehicle, index)"
          />
          <Button
            v-if="vehicle.docs == undefined"
            class="p-button-secondary p-mx-1"
            icon="pi pi-file"
            label="Documentos"
            @click="getDocuments(vehicle.vehicle_id, index)"
          />
          <Button
            class="p-button-success p-mt-1"
            icon="pi pi-plus"
            label="Relatório"
            @click="addReport(vehicle)"
          />
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import userService from "../services/user.service";
import fleetService from "../services/fleet.service";
import ChangeConductorForm from "../components/fleet/ChangeConductorForm.vue";
import ReportForm from "../components/fleet/ReportForm.vue";
export default {
  name: "HomeFleet",
  components: {
    Loading,
    ChangeConductorForm,
    ReportForm
  },
  data() {
    return {
      loading: true,
      vehicles: [],
      changeConductor: {
        show: false,
        vehicle: {},
      },
      reportModel: {
        show: false,
        vehicleId: null,
        reportId: null,
        title: null,
      },
    };
  },
  async created() {
    this.vehicles = await userService.getMyVehicles();
    this.loading = false;
  },
  methods: {
    setNewCondutor(vehicle, index) {
      let vehicleAux = { ...vehicle.vehicle };
      vehicleAux.current_condutor = { employee_id: vehicle.employee_id };
      return (this.changeConductor = {
        show: true,
        vehicle: vehicleAux,
        index: index,
      });
    },
    changeConductorCanceled() {
      return (this.changeConductor = {
        show: false,
        vehicle: {},
        index: null,
      });
    },
    conductorChanged(conductor) {
      if (conductor.status == "ok") {
        this.vehicles.splice(this.changeConductor.index, 1);
      }
      return this.changeConductorCanceled();
    },
    async getDocuments(vehicleId, index) {
      console.log(vehicleId);
      this.loading = true;
      this.vehicles[index]["docs"] = await fleetService.getPublicDocs(
        vehicleId
      );
      return (this.loading = false);
    },
    downloadFile(fileInfo) {
      return fleetService
        .getDocument(fileInfo.vehicle_id, fileInfo.id)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileInfo.file_origin_name);
          document.body.appendChild(link);
          link.click();
        });
    },
    addReport(info) {
      let title = `Veículo ${info.vehicle.brand} - ${info.vehicle.model} | ${info.vehicle.registration}`;
      return (this.reportModel = {
        show: true,
        vehicleId: info.vehicle.id,
        reportId: null,
        title: title,
      });
    },
    closeReportForm() {
      return (this.reportModel = {
        show: false,
        vehicleId: null,
        reportId: null,
        title: null,
      });
    }
  },
};
</script>
