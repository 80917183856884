<template>
  <div>
    <Button
      label="Intervenções por concluir"
      class="p-button-text p-button-lg"
      v-if="tasks.length > 0"
    />
    <div class="p-grid p-fluid dashboard p-jc-center">
      <div class="p-col-12 p-lg-12">
        <div
          class="card summary p-shadow-8 p-grid"
          v-for="task in tasks"
          :key="task.id"
        >
          <div class="p-col-3 p-lg-3 middle">
            <b>{{ getDate(task.start) }}</b>
            {{ getTime(task.start) }} - {{ getTime(task.end) }}
          </div>
          <div class="p-col-8 p-lg-8">
            <div v-if="task.entity_name != ''">
              <b>{{ task.entity_name }}</b>
            </div>
            <div v-if="task.pat_service_type != ''">
              <b>Tipo de Serviço: </b>{{ task.pat_service_type }}
            </div>
            <div><b>Pat: </b>{{ task.pat_number }}</div>
            <div v-if="task.pat_title != ''">
              <b>Título: </b>{{ task.pat_title }}
            </div>
            <div v-if="task.note != ''"><b>Nota: </b>{{ task.note }}</div>
          </div>
          <div class="p-col-1 p-lg-1 middle">
            <Button
              icon="pi pi-check"
              class="p-button-rounded"
              v-tooltip="'Concluir intervenção'"
              @click="concludeTask(task)"
            />
          </div>
        </div>
      </div>
    </div>

    <Dialog
      id="'survey-home'"
      :visible.sync="showSurveyForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Servey Info'"
    >
      <SurveyForm
        :service="taskInEdition"
        v-on:submitSurveyForm="surveyFormSubmited"
        v-on:cancelSurveyForm="surveyFormClose"
      />
    </Dialog>
    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Concluir intervenção'"
    >
      <form name="taskForm" class="p-col-12" @submit.prevent="submitTaskForm">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <b>PAT: </b> {{ taskInEdition.pat_number }}
          </div>
          <div
            class="p-field p-col-12"
            v-if="taskInEdition.note != 'pat_title'"
          >
            <b>Título: </b> {{ taskInEdition.pat_title }}
          </div>
          <div class="p-field p-col-12" v-if="taskInEdition.note != ''">
            <b>Nota: </b> {{ taskInEdition.note }}
          </div>
          <div class="p-field p-col-12 p-md-3" style="margin-top: 10px">
            <span class="p-float-label">
              <label for="inputtext">Hora de início da deslocação</label>
            </span>
            <v-date-picker
              name="start"
              mode="time"
              class="p-mt-2 width-100"
              v-model="taskInEdition.start"
              v-validate="'required|date_format:H:mm'"
              is24hr
            />
            <small v-if="errors.has('start')" class="p-error" role="alert"
              >Hora de início da deslocação é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-3" style="margin-top: 10px">
            <span class="p-float-label">
              <label for="inputtext">Hora de início do serviço</label>
            </span>
            <v-date-picker
              name="startService"
              mode="time"
              class="p-mt-2 width-100"
              v-model="taskInEdition.startService"
              v-validate="'required|date_format:H:mm|after_equal_start'"
              is24hr
            />
            <small
              v-if="errors.has('startService')"
              class="p-error"
              role="alert"
              >Hora de início do serviço é obrigatório e maior ou igual que o
              inicio da deslocação</small
            >
          </div>
          <div class="p-field p-col-12 p-md-3" style="margin-top: 10px">
            <span class="p-float-label">
              <label for="inputtext">Hora de fim do serviço</label>
            </span>
            <v-date-picker
              name="endService"
              mode="time"
              class="p-mt-2 width-100"
              v-model="taskInEdition.endService"
              v-validate="'required|date_format:H:mm|after_start_service'"
              is24hr
            />
            <small v-if="errors.has('endService')" class="p-error" role="alert"
              >Hora de fim do serviço é obrigatório e maior qua a hora de inicio
              do serviço</small
            >
          </div>
          <div class="p-field p-col-12 p-md-3" style="margin-top: 10px">
            <span class="p-float-label">
              <label for="inputtext">Hora de fim da deslocação</label>
            </span>
            <v-date-picker
              name="end"
              mode="time"
              class="p-mt-2 width-100"
              v-model="taskInEdition.end"
              v-validate="'required|date_format:H:mm|after_equals_end_service'"
              is24hr
            />
            <small v-if="errors.has('end')" class="p-error" role="alert"
              >Hora de fim da deslocação é obrigatório e maior ou igual que a
              hora de fim do serviço</small
            >
          </div>
          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="resume"
                name="resume"
                :autoResize="true"
                v-model="taskInEdition.resume"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('resume') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component'
                ]"
                rows="5"
              />
              <label for="inputtext">Trabalho efectuado</label>
            </span>
            <small v-if="errors.has('resume')" class="p-error" role="alert">
              Descreva o trabalho efectuado
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="description"
                v-model="taskInEdition.description"
                :autoResize="true"
                v-bind:class="[
                  { 'p-error': errors.has('description') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component'
                ]"
                name="description"
                rows="5"
              />
              <label for="inputtext">Pendente para a próxima intervenção</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-text-right"
            style="color: #91969a"
          >
            <small>
              Criado por {{ taskInEdition.created_by }}
              {{ taskInEdition.created_at }}
              <br />
              Última alteração por {{ taskInEdition.updated_by }}
              {{ taskInEdition.updated_at }}
            </small>
          </div>
          <div class="p-grid p-jc-center p-col-12">
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-check"
                label="Guardar"
                class="p-button-success"
                @click="submitTaskForm()"
              />
            </div>
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-times"
                label="Cancelar"
                @click="closeTaskForm()"
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import supportService from "../services/support.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import VeeValidate from "vee-validate";
import SurveyForm from "./HomeSurveyForm";
export default {
  name: "HomeTasksListUnrealized",
  components: {
    SurveyForm
  },
  data() {
    return {
      tasks: [],
      showForm: false,
      showSurveyForm: false,
      taskInEdition: {}
    };
  },
  async created() {
    await this.getTasksUnrealized();
    this.$emit("getTasksToComplete", this.tasks.length);
    VeeValidate.Validator.extend("after_equal_start", {
      validate: () =>
        new Promise(resolve => {
          resolve({
            valid: this.taskInEdition.startService >= this.taskInEdition.start
          });
        })
    });
    VeeValidate.Validator.extend("after_start_service", {
      validate: () =>
        new Promise(resolve => {
          resolve({
            valid:
              this.taskInEdition.endService > this.taskInEdition.startService
          });
        })
    });
    VeeValidate.Validator.extend("after_equals_end_service", {
      validate: () =>
        new Promise(resolve => {
          resolve({
            valid: this.taskInEdition.end >= this.taskInEdition.endService
          });
        })
    });
  },
  methods: {
    getTasksUnrealized() {
      return supportService
        .getTasksUnrealized()
        .then(response => (this.tasks = response));
    },
    getDate(date) {
      return getOnlyDate(new Date(date));
    },
    getTime(date) {
      return getOnlyTime(new Date(date));
    },
    closeTaskForm() {
      this.$validator.pause();
      this.$validator.reset();
      this.taskInEdition = {};
      this.showForm = false;
    },
    concludeTask(task) {
      this.taskInEdition = { ...task };
      this.taskInEdition.start = new Date(this.taskInEdition.start);
      this.taskInEdition.end = new Date(this.taskInEdition.end);
      if (this.taskInEdition.startService == "") {
        this.taskInEdition.startService = this.taskInEdition.start;
      } else {
        this.taskInEdition.startService = new Date(
          `${getOnlyDate(this.taskInEdition.start)} ${
            this.taskInEdition.startService
          }`
        );
      }
      if (this.taskInEdition.endService == "") {
        this.taskInEdition.endService = this.taskInEdition.end;
      } else {
        this.taskInEdition.endService = new Date(
          `${getOnlyDate(this.taskInEdition.end)} ${
            this.taskInEdition.endService
          }`
        );
      }
      if (
            this.taskInEdition.pat_service_type == "Survey" &&
            this.taskInEdition.pat_technician_resp_user_id == this.$store.state.auth.user.id
          ) {
            return (this.showSurveyForm = true);
          }
          return this.showForm = true;
    },
    submitTaskForm() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let formData = {
          start: getOnlyTime(this.taskInEdition.start),
          startService: getOnlyTime(this.taskInEdition.startService),
          endService: getOnlyTime(this.taskInEdition.endService),
          end: getOnlyTime(this.taskInEdition.end),
          resume: this.taskInEdition.resume,
          description: this.taskInEdition.description
        };

        supportService
          .setTaskRealized(this.taskInEdition.id, formData)
          .then(response => {
            if (!response) {
              this.closeTaskForm();
              return this.$toast.add({
                severity: "error",
                summary: "Erro ao gravar",
                detail: `Ocorreu um erro ao gravar ao concluir a intervenção`,
                life: 3000
              });
            }
            let taskIndex = this.tasks.findIndex(task => {
              return task.id == this.taskInEdition.id;
            });

            if (taskIndex >= 0) {
              this.tasks.splice(taskIndex, 1);
            }
            this.$emit("getTasksToComplete", this.tasks.length);
            this.closeTaskForm();
            return this.$toast.add({
              severity: "success",
              summary: "Intervenção Concluída",
              detail: "A intervenção foi concluída com sucesso",
              life: 3000
            });
          });
      });
    },
    surveyFormClose() {
      this.$validator.pause();
      this.$validator.reset();
      this.taskInEdition = {};
      this.showSurveyForm = false;
    },
    surveyFormSubmited() {
      this.showSurveyForm = false;
      this.showForm = true;
    },
  }
};
</script>
<style>
.middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.width-100 {
  width: 100%;
}
</style>
