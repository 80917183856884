<template>
  <div class="card p-shadow-6" :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <Button
      label="Por fazer"
      class="p-button-text p-button-lg cursor-default"
    />
    <div class="p-grid p-fluid dashboard p-jc-center">
      <div
        v-if="
          surveysToMarkContactManager &&
          surveysToMarkContactManager.userSurveysToMark > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Por Marcar (Contactar Gestor)')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys por marcar (Contactar Gestor)</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-green':
                  surveysToMarkContactManager.userSurveysToMark == 0,
              },
              {
                'pats-yellow':
                  surveysToMarkContactManager.userSurveysToMark > 0,
              },
            ]"
          >
            {{ surveysToMarkContactManager.userSurveysToMark }}
          </span>
        </div>
      </div>

      <div
        v-if="
          surveysAttemptSchedule &&
          surveysAttemptSchedule.userSurveysAttemptSchedule > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Tentativa Marcação')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys Tentativa Marcação</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-green':
                surveysAttemptSchedule.userSurveysAttemptSchedule == 0,
              },
              {
                'pats-yellow':
                surveysAttemptSchedule.userSurveysAttemptSchedule > 0,
              },
            ]"
          >
            {{ surveysAttemptSchedule.userSurveysAttemptSchedule }}
          </span>
        </div>
      </div>

      <div
        v-if="surveysToMark && surveysToMark.userSurveysToMark > 0"
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Por Marcar')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys por marcar</span>
          <span
            v-bind:class="[
              'count',
              { 'pats-green': surveysToMark.userSurveysToMark == 0 },
              { 'pats-yellow': surveysToMark.userSurveysToMark > 0 },
            ]"
          >
            {{ surveysToMark.userSurveysToMark }}
          </span>
        </div>
      </div>
      <div
        v-if="surveysUnfinished && surveysUnfinished.userSurveysUnfinished > 0"
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Por Realizar')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys por realizar</span>
          <span
            v-bind:class="[
              'count',
              { 'pats-green': surveysUnfinished.userSurveysUnfinished == 0 },
              { 'pats-yellow': surveysUnfinished.userSurveysUnfinished > 0 },
            ]"
          >
            {{ surveysUnfinished.userSurveysUnfinished }}
          </span>
        </div>
      </div>
      <div
        v-if="
          surveysWithoutReport &&
          surveysWithoutReport.userSurveysWithoutReport > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Sem Relatório')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys sem relatório</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-green':
                  surveysWithoutReport.userSurveysWithoutReport == 0,
              },
              {
                'pats-yellow':
                  surveysWithoutReport.userSurveysWithoutReport > 0,
              },
            ]"
          >
            {{ surveysWithoutReport.userSurveysWithoutReport }}
          </span>
        </div>
      </div>
      <!-- <div
        v-if="surveysUnclosed && surveysUnclosed.userSurveysUnclosed > 0"
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Por Concluir')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys por concluir</span>
          <span
            v-bind:class="[
              'count',
              { 'pats-green': surveysUnclosed.userSurveysUnclosed == 0 },
              { 'pats-yellow': surveysUnclosed.userSurveysUnclosed > 0 },
            ]"
          >
            {{ surveysUnclosed.userSurveysUnclosed }}
          </span>
        </div>
      </div> -->
      <div
        v-if="
          surveysWithoutBudget &&
          surveysWithoutBudget.userSurveyswithoutBudget > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('surveys/Sem Orçamento')"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Surveys sem orçamento</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-green':
                  surveysWithoutBudget.userSurveyswithoutBudget == 0,
              },
              {
                'pats-yellow':
                  surveysWithoutBudget.userSurveyswithoutBudget > 0,
              },
            ]"
          >
            {{ surveysWithoutBudget.userSurveyswithoutBudget }}
          </span>
        </div>
      </div>
      <!-- <div
        v-if="
          installationsInPilotUndated && installationsInPilotUndated.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Em Piloto Sem Data')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações em piloto sem data</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsInPilotUndated.user > 0,
              },
            ]"
          >
            {{ installationsInPilotUndated.user }}
          </span>
        </div>
      </div> -->
      <div
        v-if="
          installationsWithoutInstallSheet && installationsWithoutInstallSheet.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Sem Folha de Instalação')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações sem folha de instalação</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsWithoutInstallSheet.user > 0,
              },
            ]"
          >
            {{ installationsWithoutInstallSheet.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsUnfinishedInstallationSheet && installationsUnfinishedInstallationSheet.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Folha de Instalação por Concluir')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações com folha de instalação por concluir</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsUnfinishedInstallationSheet.user > 0,
              },
            ]"
          >
            {{ installationsUnfinishedInstallationSheet.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsNoDeliveryDate && installationsNoDeliveryDate.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/A Decorrer')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações a decorrer</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsNoDeliveryDate.user > 0,
              },
            ]"
          >
            {{ installationsNoDeliveryDate.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsNoWorksheet && installationsNoWorksheet.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Sem Folha de Obra')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações sem folha de obra</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsNoWorksheet.user > 0,
              },
            ]"
          >
            {{ installationsNoWorksheet.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsNoDateReport && installationsNoDateReport.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Sem Relatório')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações sem relatório</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsNoDateReport.user > 0,
              },
            ]"
          >
            {{ installationsNoDateReport.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsTechnicianToValidate && installationsTechnicianToValidate.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Por Validar')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações por validar</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsTechnicianToValidate.user > 0,
              },
            ]"
          >
            {{ installationsTechnicianToValidate.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          installationsUnfinished && installationsUnfinished.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('installations/Por Concluir')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Instalações por concluir</span>
          <span
            v-bind:class="[
              'count',
              {
                'pats-yellow': installationsUnfinished.user > 0,
              },
            ]"
          >
            {{ installationsUnfinished.user }}
          </span>
        </div>
      </div>
      <div
        v-if="
          assistsPendingNextIntervention && assistsPendingNextIntervention.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('assists/Pendente Próxima Intervenção')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Assistências com Pendente Próxima Intervenção</span>
          <span v-bind:class="['count', 'pats-red']">
            {{ assistsPendingNextIntervention.user }}
          </span>
        </div>
      </div>

      <div
        v-if="
          assistsPending && assistsPending.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('assists/Pendente')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Assistências Pendentes</span>
          <span v-bind:class="['count', 'pats-red']">
            {{ assistsPending.user }}
          </span>
        </div>
      </div>

      <div
        v-if="
          assistsScheduledWithoutIntervention && assistsScheduledWithoutIntervention.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('assists/Agendado Sem Intervenção')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Assistências Agendadas Sem Intervenção</span>
          <span v-bind:class="['count', 'pats-red']">
            {{ assistsScheduledWithoutIntervention.user }}
          </span>
        </div>
      </div>

      <div
        v-if="
          assistsSlaOutOfDate && assistsSlaOutOfDate.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('assists/SLA Ultrapassado')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Assistências com SLA Ultrapassado</span>
          <span v-bind:class="['count', 'pats-red']">
            {{ assistsSlaOutOfDate.user }}
          </span>
        </div>
      </div>

      <div
        v-if="
          assistsCompletedByClose && assistsCompletedByClose.user > 0
        "
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('assists/Concluido Por Fechar')"
      >
        <div class="card summary p-shadow-8">
          <span class="title">Assistências Concluidas Por Fechar</span>
          <span v-bind:class="['count', 'pats-red']">
            {{ assistsCompletedByClose.user }}
          </span>
        </div>
      </div>

      <div
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('competency-profile-user')"
        v-if="toFillUserCompetency > 0"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Competências por classificar </span>
          <span v-bind:class="['count', 'pats-red']">
            {{ toFillUserCompetency }}
          </span>
        </div>
      </div>

      <div
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('vacations')"
        v-if="vacationsStatistics.authorized - vacationsStatistics.marked > 0"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Férias por marcar </span>
          <span v-bind:class="['count', 'pats-red']">
            {{ vacationsStatistics.authorized - vacationsStatistics.marked }}
          </span>
        </div>
      </div>

      <div
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('hours')"
        v-if="userOpenDays.totalLastYear > 0"
      >
        <div class="card summary p-shadow-8">
          <span class="title">
            Dias Por fechar - {{ userOpenDays.year - 1 }}
          </span>
          <span v-bind:class="['count', 'pats-red']">
            {{ userOpenDays.totalLastYear }}
          </span>
        </div>
      </div>

      <div
        class="p-col-12 p-lg-12 cursor-pointer"
        v-on:click="$router.push('hours')"
        v-if="userOpenDays.totalCurrentYear > 0"
      >
        <div class="card summary p-shadow-8">
          <span class="title"> Dias Por fechar - {{ userOpenDays.year }} </span>
          <span v-bind:class="['count', 'pats-red']">
            {{ userOpenDays.totalCurrentYear }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import surveysService from "../services/surveys.service";
import installationsService from "../services/installations.service";
import supportService from "../services/support.service";
import competencyService from "../services/competency.service";
import vacationsService from "../services/vacations.service";
import userService from "../services/user.service";
import loading from "vue-loading-overlay";
export default {
  name: "HomeToClose",
  components: {
    Loading: loading,
  },
  data() {
    return {
      loading: true,
      toFillUserCompetency: 0,
      vacationsStatistics: {},
      userOpenDays: {
        totalCurrentYear: 0,
        totalLastYear: 0,
        year: new Date().getFullYear(),
      },
      surveysToMarkContactManager: null,
      surveysAttemptSchedule: null,
      surveysToMark: null,
      surveysUnfinished: null,
      surveysWithoutReport: null,
      // surveysUnclosed: null,
      surveysWithoutBudget: null,
      installationsInPilotUndated: null,
      installationsWithoutInstallSheet: null,
      installationsUnfinishedInstallationSheet: null,
      installationsNoDeliveryDate: null,
      installationsNoWorksheet: null,
      installationsNoDateReport: null,
      installationsTechnicianToValidate: null,
      installationsUnfinished: null,
      assistsPendingNextIntervention: null,
      assistsPending: null,
      assistsScheduledWithoutIntervention: null,
      assistsSlaOutOfDate: null,
      assistsCompletedByClose: null,
    };
  },
  async created() {
    this.surveysToMarkContactManager =
      await surveysService.countToMarkContactManager();
    this.surveysAttemptSchedule =
      await surveysService.countAttemptSchedule();
    this.surveysToMark = await surveysService.countToMark();
    this.surveysUnfinished = await surveysService.countUnfinished();
    this.surveysWithoutReport = await surveysService.countWithoutReport();
    if (
      this.$store.state.auth.user.isAdmin ||
      this.$store.state.auth.user.isEsa
    ) {
      //this.surveysUnclosed = await surveysService.countUnclosed();
      this.surveysWithoutBudget = await surveysService.countWithoutBudget();
    }

    // this.installationsInPilotUndated =
    //   await installationsService.countInPilotUndated();
    this.installationsWithoutInstallSheet =
      await installationsService.countWithoutInstallationSheet();
    this.installationsUnfinishedInstallationSheet = await installationsService.countInstallationSheetToBeCompleted();
    this.installationsNoDeliveryDate = await installationsService.countWithoutDeliveryDate();
    this.installationsNoWorksheet = await installationsService.countWithoutWorksheet();
    this.installationsNoDateReport = await installationsService.countUndatedReport();
    if (
      this.$store.state.auth.user.isAdmin ||
      this.$store.state.auth.user.isEsa
    ) {
      this.installationsTechnicianToValidate =  await installationsService.countTechnicianToValidate();
      this.installationsUnfinished = await installationsService.countUnfinished();
    }
    this.assistsPendingNextIntervention = await supportService.countPendingNextIntervention();
    this.assistsPending = await supportService.countPending();
    this.assistsScheduledWithoutIntervention = await supportService.countScheduledWithoutIntervention();
    this.assistsSlaOutOfDate = await supportService.countSlaOutOfDate();
    if (
      this.$store.state.auth.user.isAdmin ||
      this.$store.state.auth.user.isEsa
    ) {
      this.assistsCompletedByClose =  await supportService.countCompletedByClose();
    }
    await this.getVacations();

    await this.competencyToFill().then((response) => {
      this.toFillUserCompetency = response.toFillUserCompetency;
    });

    await this.openDays(this.userOpenDays.year - 1).then(
      (response) => (this.userOpenDays.totalLastYear = response.openDays)
    );

    await this.openDays(this.userOpenDays.year).then(
      (response) => (this.userOpenDays.totalCurrentYear = response.openDays)
    );
    this.loading = false;
  },
  methods: {
    competencyToFill() {
      return competencyService.countUserCompetencyToFill(
        this.$store.state.auth.user.id
      );
    },
    async getVacations() {
      this.vacationsStatistics = {
        year: new Date().getFullYear() - 1,
        authorized: 22,
        marked: 0,
      };
      return vacationsService
        .getVacationsByYearList(
          this.$store.state.auth.user.id,
          this.vacationsStatistics.year
        )
        .then((response) => {
          response.forEach((vacation) => {
            this.vacationsStatistics.marked += vacation.number_days;
          });
        });
    },
    openDays(year) {
      return userService.getOpenDays(this.$store.state.auth.user.id, year);
    },
  },
};
</script>
<style>
.count {
  padding: 0px 14px !important;
}
.pats-green {
  background-color: #20d077;
}
.pats-red {
  background-color: #ef6262;
}
.pats-yellow {
  background-color: #f9c851;
}

.dashboard .summary .title {
  font-size: 15px;
}
.p-button-lg {
  color: #000 !important;
  font-size: 1.75rem !important;
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
</style>
