<template>
  <div class="p-col-12">
    <div class="card">
      <form name="taskForm" class="p-col-12" @submit.prevent="submitSurveyForm">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6 p-md-4">
            <b>PAT: </b> {{ service.pat_number }}
          </div>
          <div
            class="p-field p-col-6 p-md-4"
            v-if="service.note != 'pat_title'"
          >
            <b>Título: </b> {{ service.pat_title }}
          </div>
          <div class="p-field p-col-12 p-md-4" v-if="service.note != ''">
            <b>Nota: </b> {{ service.note }}
          </div>

          <div class="p-field p-col-12 p-md-12">
            <h4>Tem toda a informação para efectuar o relatório do survey?<br />
            Já não é necessário marcar mais nenhuma intervenção para este survey?</h4>
            <InputSwitch v-model="surveyConcluded" />
          </div>

          <div class="p-field p-col-12 p-md-12" v-if="surveyConcluded">
            <h6>Deseja inserir agora o relatório do survey?</h6>
            <InputSwitch v-model="insertReport" />
          </div>

          <div
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
            v-if="surveyConcluded && insertReport"
          >
            <span class="p-float-label">
              <Textarea
                id="surveyReport"
                name="surveyReport"
                :autoResize="true"
                v-validate="(surveyConcluded && insertReport) ? 'required' : ''"
                v-model="service.pat_solution"
                v-bind:class="[
                  { 'p-error': errors.has('surveyReport') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="inputtext">Relatório do Survey</label>
            </span>
            <small
              v-if="errors.has('surveyReport')"
              class="p-error"
              role="alert"
            >
              Escreva o Relatório do Survey
            </small>
            <h5>
              <b>NOTA:</b> Se o relatório já contiver dados, foram lançados
              noutra intervenção.
            </h5>
            <h5>Antes de guardar os dados valide tudo.</h5>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-text-right"
            style="color: #91969a"
          >
            <small>
              Criado por {{ service.created_by }} {{ service.created_at }}
              <br />
              Última alteração por {{ service.updated_by }}
              {{ service.updated_at }}
            </small>
          </div>

          <div class="p-grid p-jc-center p-col-12">
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-check"
                label="Guardar"
                class="p-button-success"
                @click="submitSurveyForm()"
              />
            </div>
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-times"
                label="Cancelar"
                @click="cancelSurveyForm()"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
export default {
  name: "HomeSurveyForm",
  props: ["service"],
  data() {
    return {
      loading: false,
      surveyConcluded: false,
      insertReport: true,
    };
  },
  mounted() {},
  methods: {
    submitSurveyForm() {
      this.loading = true;

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        return this.setSurveyConclued();
      });
    },
    cancelSurveyForm() {
      this.$emit("cancelSurveyForm");
    },
    setSurveyConclued() {
      if (!this.surveyConcluded) {
        return this.$emit("submitSurveyForm");
      }
      const status = "2. Realizado";
      return patsService
        .updatedStatus(this.service.pat_number, { status: status })
        .then((response) => {
          if (!response || response.status != status) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao marcar como realizado o survey`,
              detail: "",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: `Survey realizado`,
              detail: `Survey com o pat ${this.service.pat_number}
              mudou o estado para realizado`,
              life: 3000,
            });
          }
          if (this.insertReport) {
            return this.setSolution();
          }
          return this.$emit("submitSurveyForm");
        });
    },
    setSolution() {
      return patsService
        .setSolution(this.service.pat_number, {
          solution: this.service.pat_solution,
        })
        .then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o relatório do survey",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Relatório inserido",
              detail: "O relatório inserido com sucesso",
              life: 3000,
            });
          }
          return this.$emit("submitSurveyForm");
        });
    },
  },
};
</script>
