import firebase from "firebase/app";
import "firebase/firebase-messaging";
import notificationsService from "./services/notifications.service";
var userId;

export default async function firebaseInitialize(user) {
  userId = user;
  const firebaseConfig = await notificationsService
    .getNotificationConfig()
    .then((config) => {
      return JSON.parse(window.atob(config));
    });

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const messaging = firebase.messaging();

  messaging
    .getToken({
      vapidKey: firebaseConfig.vapidKey,
    })
    .then((currentToken) => {
      if (currentToken) {
        let bodyparams = {
          user_id: userId,
          token: currentToken,
        };
        notificationsService.validadeTokenExist(bodyparams);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });

  messaging.onMessage(function (payload) {
    var notificationTitle = payload.notification.title;
    var notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
      image: payload.notification.image,
      sound: payload.notification.sound,
    };

    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function () {
      window.open(payload.notification.click_action, "_blank");
      notification.close();
    };
  });
}
